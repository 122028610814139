.service-box-img {
  background-color: #00708e;
}

.service-box-img img {
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* .content-dynamic .content-dynamic-row:nth-child(odd) {
} */

.content-dynamic .content-dynamic-row:nth-child(even) {
  flex-direction: row-reverse;
}

.content-dynamic-row:nth-child(odd) .service-box-img {
  width: 90%;
}
.content-dynamic-row:nth-child(odd) .service-box-img img {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  left: 12px;
}
.content-dynamic-row:nth-child(even) .service-box-img {
  margin-left: 10%;
}
.content-dynamic-row:nth-child(even) .service-box-img img {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  left: -12px;
}
.content-dynamic .content-dynamic-row {
  margin-top: 70px;
}
.content-dynamic-row:nth-child(odd) .about-us-texts {
  padding-left: 40px;
}
.content-dynamic-row p {
  min-height: 100px;
}

.upcommig-event-div {
  height: 100%;
}
.upcommig-event {
  height: 300px;
  object-fit: cover;
  width: 100%;
  object-position: center center;
}
.event-avatars img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}
.upcommig-event-date {
  margin-bottom: 0px;
  margin-top: -15px;
}
.upcommig-event-div:hover {
  transition: all 0.5s ease-in-out 0s;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  color: #00708ed9;
  box-shadow: 0 0 31px 5px;
}
.service-box-img-right img {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  left: -12px;
}

.sign-up-form {
  padding: 30px 30px;
  border-radius: 8px;
}

.toast-updated {
  z-index: 99;
  position: fixed;
  bottom: 10px;
  right: 20px;
  padding: 20px;
}

.upload-btn-wrapper {
  display: flex;
}

.upload-btn-wrapper button {
  height: 44px;
  margin-left: 15px;
  margin-top: 26px;
}

.delete-buttib-icons {
  color: #f5365c;
  border: 0px;
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.meet-our-teams-coulmn-custom {
  height: 330px;
}

.meet-our-teams-coulmn-custom img {
  height: 280px;
  width: 100%;
  object-fit: cover;
}

.meet-our-teams-coulmn-custom-inner {
  display: grid;
  place-items: center;
  align-items: center;
}

.meet-our-teams-coulmn-custom-inner {
  display: grid;
  place-items: center;
}

.live-streaming-host {
  width: 400px;
  height: 400px;
}

.control-audios {
  position: relative;
  top: 8px;
  left: 10px;
  height: 30px;
}

.close-video {
  position: absolute;
  right: -10px;
  opacity: 1;
  top: -10px;
}

.play-btn {
  background: transparent;
  border: 0px;
  font-size: 26px;
}

.close-video span {
  background-color: red !important;
  color: #fff !important;
  border-radius: 50%;
  opacity: 1 !important;
  padding: 5px 5px;
  height: 30px !important;
  width: 30px !important;
  border: 2px solid #000 !important;
}

.text-stop-long {
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toast-header .close > span:not(.sr-only) {
  color: #fff;
  opacity: 1;
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: #000;
  line-height: 20px;
  height: 20px;
  font-size: 15px;
}

.edit-btn {
  border: 0;
  background-color: #ffd600;
  color: #000;
  padding: 10px 50px;
  border-radius: 4px;
}

.delete-btn {
  border: 0;
  background-color: #f5365c;
  padding: 10px 50px;
  border-radius: 4px;
  color: #fff;
}

.add-btn {
  border: 0;
  background-color: #2dce89;
  color: #fff;
  padding: 8px 50px;
  border-radius: 4px;
}

.spinner-border {
  width: 50px !important;
  height: 50px !important;
}

.avatar-custom {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.category-btns {
  display: flex;
  justify-content: space-between;
}
.show-img-file {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top center;
}
.content-td {
  white-space: unset !important;
  max-width: 300px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 100px;
}
.contents-upcommings {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.paypal-moadal {
  padding: 0px 10px !important;
}

.paypap-form {
  background: linear-gradient(87deg, #0099db 0, #004c9e 100%) !important;
}

.paypal-text {
  color: #003087;
}

.btn-download-tracks {
  font-size: 12px;
}

.songs-table {
  min-height: 70vh;
}

.songs-table table td {
  vertical-align: middle;
}

.amount-in-card {
  float: right;
}

.card-titles {
  font-weight: bold;
}

.genders-in-card {
  margin-top: -15px;
  font-size: 14px;
}

.card-datess {
  font-size: 14px;
}

.date-timess {
  font-size: 18px;
  margin-right: 6px;
  font-weight: 700;
}

.side-bar-icons {
  margin-right: 10px;
  font-size: 16px;
  position: relative;
  top: -1px;
}

.active .side-bar-icons {
  color: #1171ef;
}

#sidenav-main .nav-link.active {
  color: #1171ef !important;
}

.audio-lists-panel-header-title {
  color: #fff;
}

.no-strss {
  border: 0px !important;
}

.nav-header-item-front a:hover {
  color: #00708e !important;
}

.select-timnings {
  background-color: #fff;
  padding: 10px 10px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #00708e;
}

.link-zoom {
  background-color: #e9ecef;
  width: 54px;
  text-align: center;
  position: relative;
  left: 5px;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-avatar {
  display: inline-block;
  text-align: center;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 50%;
  height: 62px;
  margin-right: 10px;
  border: 2px solid #000;
}

.date-avatar-day {
  color: #119aef;
  font-weight: bold;
  margin-bottom: -10px;
  margin-top: 5px;
}

.session-start-dates {
  font-size: 10px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 3px;
}

.date-avatar-month {
  color: #119aef;
  font-weight: 600;
  margin-bottom: -3px;
  position: relative;
  left: 0px;
}

.dates-boses {
  display: flex;
}

.session-names {
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.session-types {
  font-size: px;
}

.session-end-dates {
  font-size: 14px;
  font-weight: 400;
  color: #58585896;
}

.information-text {
  font-size: 12px !important;
  font-family: "poppins-medium";
  margin-top: 10px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 32px;
  min-height: 32px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.videos-sessions {
  width: 100%;
  height: 190px;
  margin-top: -20px;
}

.session-end-dates {
  font-size: 12px;
}

.ession-types {
  font-size: 16px;
}

.bodies {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 4px;
}

.detail-nav {
  padding: 0px 0px;
  text-align: end;
  font-size: 14px;
}

.form-for-video {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  padding: 15px 15px;
  border-radius: 4px;
}

.no-record {
  text-align: center;
  padding-bottom: 20px;
  background-color: #fff;
  padding-top: 20px;
}

.box-designs {
  width: 100% !important;
  border: 1px solid #ced4da !important;
  width: 100% !important;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  height: 45px !important;
  padding-top: 10px !important;
}

.back-img-src {
  background-image: url("../images/back-img-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.back-img-src-inner h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #ffff;
  font-size: 24px;
}

.bg-login {
  background-color: #ffffff7d !important;
}

.text-black {
  color: #000;
}

.bg-gradient-theme {
  background: linear-gradient(87deg, #00708e, #11546c 100%) !important;
}

.active-user {
  color: #00708e !important;
}

.sign-ups-text {
  font-size: 16px;
}

.width-250 {
  width: 250px;
}

.socilas-link-for-team {
  display: flex;
  justify-content: center;
}

.media-upload-form {
  background-color: #fff;
  padding: 10px 10px;
  margin-bottom: 30px;
  border-radius: 4px;
}

.nav-tabs li button {
  padding: 10px 20px;
  margin: 0px 10px;
}

.nav-tabs li button.active {
  background-color: #5e72e4 !important;
  color: #fff !important;
}

.custom-paginations {
  padding: 10px 15px;
  border: 1px solid #1b77f0;
}

.hover-pointer:hover{ cursor: pointer; }
.custom-paginations a {
  font-size: 14px;
}

.custom-paginations.active {
  background-color: #1b77f0;
}

.custom-paginations.active a {
  color: #fff;
}

.pagination-boxes .pagination {
  justify-content: center;
}

.package-videos-card-img {
  width: 100%;
  height: 100%;
}

.select-users-td {
  width: 40px;
  position: relative;
}

.select-users-td input {
  margin: -5px auto;
}

.search-filter {
  padding: 0px 20px;
}

.dataTable-customes {
  padding: 30px 30px;
  border-radius: 10px;
  background-color: #fff;
  margin: 30px 30px;
}

.invites-btns {
  text-align: right;
  margin-top: auto;
}

.sort-by-filters-invites {
  margin-top: 15px;
}

.sort-by-filters-invites label {
  font-size: 14px;
}

.sort-by-filters-invites select {
  font-size: 14px;
}

.description-of-invites {
  display: block;
  width: 100%;
  height: 80px;
  border: 1px solid #cad1d7;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}

.videos-user-tabs {
  padding: 30px 30px;
}

.videos-user-tabs {
  background-color: #ffff;
  margin: 30px 30px;
  padding: 25px 10px;
  border-radius: 10px;
}

.file-extensions-names {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.date-expiry {
  font-size: 10px !important;
  position: absolute;
  top: 0px;
}

.listed {
  margin-top: -25px;
}

.icon-styles {
  position: absolute;
  top: 35px;
  right: 10px;
}

.overlay-box {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000cc;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.overlay-box-inner h2 {
  color: #fff;
  margin-bottom: 15px;
}

.more-than-18 {
  background-color: #37c83f;
  border: 0;
  padding: 8px 10px;
  width: 270px;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.more-under-15 {
  background-color: #ca3436;
  display: block;
  border: 0;
  padding: 8px 10px;
  width: 270px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
}

.overlay-box-inner {
  width: 767px;
  margin-left: auto;
  margin-right: auto;
}

.overlay-box-inner h4 {
  color: #fff;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 24px;
}

.overlay-box-inner p {
  color: #fff;
  font-size: 14px;
}

@media (max-width: 767px) {
  .overlay-box-inner {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.nav-header-item-front .contact-btn:hover {
  color: #fff !important;
}

.package-videos-card-h1 {
  font-size: 18px;
  line-height: 20px;
  font-family: "poppins-medium" !important;
  margin-top: 10px;
}

.dox-icons {
  width: 16px;
}

.shadow-main {
  background-color: #fff;
  margin: 10px 25px;
  border-radius: 5px;
}

.train-pains {
  font-size: 36px;
}


.home-slides-s-1 {
  background-image: url("../images/slide-1.webp");
  background-position: center;
  background-size: cover;
}

.home-slides-s-2 {
  background-image: url("../images/image-slide-11.webp");
  background-position: center;
  background-size: cover;
}

.home-slides-s-3 {
  background-image: url("../images/normals.webp");
  background-position: top;
  background-size: cover;
}

.customs-trainers {
  max-height: 100px;
  position: relative;
}

.slide-for-text {
  -webkit-transform: translateY(-550px);
  transform: translateY(-550px);
  margin-left: 50px;
}

.customs-trainers img {
  width: 100%;
  object-fit: cover;
  height: 1000px;
}

.clubs-slide-btns-box {
  position: absolute;
  bottom: 230px;
  right: 50px;
}

.customs-trainers-enter img {
  height: 1000px;
}

.customs-trainers-enter {
  height: 1000px;
  max-height: 1000px;
}

.clubs-slide-btns-box-end {
  z-index: 9999;
  position: relative;
  top: -60px;
  text-align: right;
  padding-right: 20px;
}

.new-back-images {
  background-position: top;
  height: 1000px;
  background-size: cover;
}

.new-back-images-1 {
  background-image: url("../images/image-slide-11.webp");
}

.new-back-images-2 {
  background-image: url("../images/set-new-1.webp");
}

.new-back-images-4 {
  background-image: url("../images/slide-4.webp");
}

.text-ghatya h2 {
  font-size: 48px;
  margin-left: 5px;
  margin-right: 5px;
  color: #ff0e0e;
  cursor: pointer;
  display: inline;
}


.text-ghatya-blue h2 {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 48px;
  color: #00708e;
  cursor: pointer;
  display: inline;
}

.color-greens h2 {
  cursor: pointer;
  color: #35a971 !important;
}
.switch-group  .switch-off.btn-sm{
  padding-left: 22px;
}

.blinking {
  animation-name: updown;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transition-duration: 2s;
  transition-property: all;
  transition-delay: 0ms;
}
.category-h2{
  font-size: 26px;
  text-align: center;
  margin-top: -30px;
  margin-bottom: 10px;
}
@keyframes updown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hrs-lefts {
  margin-left: 0px;
}

.email-2 a {
  color: #fff;
}

.emsila-1 a {
  color: #fff;
}

.border-white {
  border-color: #fff !important;
  border-width: 2px !important;
}

.check-status-pendings {
  text-align: end;
  margin-top: -40px;
  margin-bottom: 20px;
}

/* .group-text-ghat {
  display: flex;
  justify-content: center;
} */

/* .group-text-ghat h2 {
  margin-left: 1.5px;
  margin-right: 1.5px;
  color: #ff0e0e !important;
} */

.traning-modals-head-buttons {
  position: absolute;
  right: 0px;
  top: -5px;
  font-size: 28px;
  background: #00708e;
  border: 0;
  z-index: 999;
  color: #fff;
}

.traning-modals-head {
  justify-content: center;
  text-align: center;
  margin-top: 15px;
}

.traning-modals {
  top: -15px;
}

.tranning-modal-content {
  text-align: center;
}

.tranning-modal-content h5 {
  font-size: 16px;
}

.tranning-modal-content p {
  font-size: 14px;
}

.traning-modals-head h3 {
  font-size: 20px;
}

.web-links {
  color: #00708e;
  font-weight: bold;
}

.hr-centers {
  border-top: 2px solid #00708e;
}

.custom-hrs {
  border-top: 2px solid #00708e;
  margin-left: 0px;
}

.cutoms-lists {
  padding-left: 20px;
}

.snormal-h4 {
  font-size: 18px;
}

.credencial-about-us {
  list-style: none;
  padding-left: 02x;
}

.credencial-about-us-box {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  text-align: center;
}

.credencial-about-us li {
  font-size: 13px;
}

.meet-our-teams-imgss-custom {
  height: 330px;
  width: 100%;
}

.no-hover:hover {
  color: #fff !important;
}

.train-the-pains {
  color: #ff0e0e;
  font-size: calc(100% + 2px);
  font-weight: 800;
}

.h4-start {
  font-family: "star-free";
}

.gym-ionc {
  width: 70px;
  position: absolute;
  top: 50%;
}

.bena-chotiya {
  font-family: "star-free";
  color: #fbaded;
  font-weight: bolder;
  font-size: 34px;
}

.overflow-containers {
  margin-top: 30px !important;
  z-index: 999;
}

.home-sliders .slick-list {
  margin-bottom: -90px;
}
.entertainment-content {
  background-color: #fff;
  margin: 10px 30px;
  border-radius: 5px;
  padding-bottom: 30px;
}
.text-for-item {
  max-width: 350px;
}
.key-update-form {
  display: flex;
}
.key-update-form button {
  margin-left: 10px;
  height: 45px;
  margin-top: auto;
  margin-bottom: auto;
}
.online-coaching-modal .modal-dialog {
  max-width: 800px;
}
.close-btn-modal {
  position: absolute;
  right: 0;
  top: 0px;
  background-color: red;
  color: #fff;
  border-radius: 50px;
  border: 0px;
  height: 27px;
  line-height: 15px;
}

.thankyou-des {
  margin-bottom: -20px;
  font-size: 18px;
  font-weight: bold;
}
.thankyou-des span {
  color: #ff0e0e;
  margin-left: 5px;
}
.description-categories {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  white-space: nowrap !important;
}
.seleced-item-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-top: 40px;
}

.services-para {
  display: inline-block !important;
  width: 180px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.description-tooltip .tooltip-inner {
  max-width: 250px;
  position: relative;
  padding: 12px 10px;
  font-size: 12px;
}

@media (max-width: 1600px) {
  .new-back-images {
    height: 800px;
  }

  .customs-trainers img {
    height: 800px;
  }

  .slide-for-text {
    transform: translateY(-500px);
  }

  .customs-trainers-enter img {
    height: 800px;
  }

  .customs-trainers-enter {
    height: 800px;
    height: 800px;
  }
}

@media (max-width: 1460px) {
  .text-ghatya h2 {
    font-size: 42px;
  }
  
  .text-ghatya-blue h2 {
    font-size: 42px;
  }
  .arrow-left {
    font-size: 30px;
}
  .nav-header-item-front .navs-item {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  .nav-header-front .header {
    padding: 0px 0px !important;
  }

  .nav-header-item-front .navs-item {
    font-size: 12px;
  }

  .new-back-images {
    height: 700px;
  }
}

@media (max-width: 1120px) {
  .customs-trainers img {
    height: 700px;
  }

  .slide-for-text {
    transform: translateY(-400px);
  }

  .new-back-images {
    height: 600px;
  }



  .customs-trainers-enter img {
    height: 600px;
  }

  .customs-trainers-enter {
    height: 600px;
    height: 600px;
  }
}

@media (max-width: 992px) {
  .text-ghatya h2 {
    font-size: 36px;
  }
  .text-ghatya-blue{
    position: relative;
    top: -15px;
  }
  .text-ghatya-blue h2 {
    font-size: 36px;
  }
  .arrow-left {
    font-size: 24px;
}

  .arrow-right-com {
    align-self: center;
    width: 100px;
}
.arrow-left-com {
  align-self: center;
  position: relative;
  top: -30px;
  width: 100px;
}
  .content-dynamic-row:nth-child(odd) .service-box-img {
    width: 100%;
  }
  .content-dynamic-row:nth-child(odd) .service-box-img img {
    left: 0px;
    padding: 12px 12px;
  }
  .service-box-img img {
    object-position: center;
  }
  .content-dynamic-row:nth-child(odd) .about-us-texts {
    padding-left: 0px;
  }
  .content-dynamic-row:nth-child(even) .service-box-img img {
    left: 0px;
    padding: 12px 12px;
  }
  .content-dynamic-row p {
    min-height: auto;
  }
  .content-dynamic-row:nth-child(even) .service-box-img {
    margin-left: 0;
  }
  .online-coaching-modal {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .online-coaching-modal .modal-dialog {
    width: 100% !important;
  }
  .service-box-img-left {
    width: 100%;
  }

  .column-direction-992 {
    flex-direction: column-reverse;
  }

  .meet-our-teams-imgss-custom {
    height: 230px;
  }

  .service-box-img-right {
    margin-left: 0;
  }

  .service-box-img-right img {
    padding-left: 12px;
    padding-right: 12px;
    left: 0px;
  }

  .service-box-img-left img {
    padding-left: 12px;
    padding-right: 12px;
    left: 0px;
  }
/* 
  .text-ghatya-blue h2 {
    font-size: 42px;
  } */

  .customs-trainers img {
    height: 600px;
  }

  .slide-for-text {
    transform: translateY(-350px);
  }
}

@media (max-width: 960px) {
  .nav-header-item-front .navs-item {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .category-h2{
  font-size: 20px;
}

  .clubs-slide-btns-box-end {
    text-align: center;
    padding-right: 0px;
  }
  .text-ghatya h2 {
    font-size: 30px;
    margin-left: 2px;
    margin-right: 2px
  }
  
  .text-ghatya-blue h2 {
    font-size: 30px;
    margin-left: 2px;
    margin-right: 2px
  }
  .arrow-left {
    font-size: 20px;
}

  .customs-trainers img {
    height: 400px;
  }

  .slide-for-text {
    transform: translateY(-300px);
    margin-left: 0px;
  }

  .clubs-slide-btns-box {
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
  }

  .customs-trainers-enter img {
    height: 400px;
  }

  .customs-trainers-enter {
    height: 400px;
    height: 400px;
  }

  .new-back-images {
    height: 400px;
  }



  .meet-our-teams-imgss-custom {
    height: auto;
  }



  .contact-us-box-img {
    height: 500px;
  }
  .hrs-lefts {
    margin-left: auto;
  }
}

@media (max-width: 576px) {
  .text-ghatya h2 {
    font-size: 18px;
  }
  
  .text-ghatya-blue h2 {
    font-size: 18px;
  }
  .arrow-left {
    font-size: 16px;
}
  .customs-trainers-enter img {
    height: 300px;
  }

  .customs-trainers-enter {
    height: 300px;
    height: 300px;
  }



  .new-back-images {
    height: 350px;
  }

  .contact-us-box-img {
    height: 350px;
  }

  .gym-ionc {
    width: 40px;
    top: 40%;
  }

  .custom-service-providers {
    text-align: center;
  }


  .traning-modals-head-buttons {
    right: 0px;
  }
}

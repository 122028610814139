@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "star-free";
  src: url("../fonts/start-free.TTF");
}

@font-face {
  font-family: "poppins-medium";
  src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "limited-edition";
  src: url("../fonts/Limited-Edition.otf");
}

@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins-Bold.ttf");
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.s-box {
  min-height: 433px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  background-color: #f7f7f9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}

label {
  margin-bottom: 3px;
}

.spinner-border {
  width: 1.3rem;
  height: 1.3rem;
  border-width: 0.1em;
}

.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 120px;
}

.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}

.success-msg .title {
  font-size: 25px;
  margin-top: 10px;
}

.limited-edition {
  font-family: "limited-edition" !important;
}

.max-registraion {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.song-cover {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.start-font {
  font-family: "star-free";
}

img {
  max-width: 100%;
}

.tranding-img {
  width: 100%;
  height: 100%;
}

.song-headers {
  background-color: #00708e;
  height: 40px;
  line-height: 40px;
  color: #fff;
}

.tranding-img img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.tranding-img img:hover {
  width: 100%;
  transition: all 0.5s ease-in-out 0s;
  transform: scale(1.1);
}

.audio-download-play {
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 280px;
}

h1 {
  font-family: "poppins-bold";
}

h2 {
  font-family: "poppins-bold";
}

h3 {
  font-family: "poppins-bold";
}

h4 {
  font-family: "poppins-medium";
}

h6 {
  font-family: "poppins-medium";
}

.build-your-body-text h2 {
  font-size: 54px;
}

.build-your-body-text h4 {
  font-size: 30px;
}

.person-gim-info h4 {
  font-family: "poppins-bold";
}

.person-gim-info h2 {
  font-family: "star-free";
  font-size: 40px;
  color: #706f01;
  margin-top: 15px;
}

.person-gim-info p {
  color: #7a7a7a;
  font-family: "poppins-light";
  font-size: 12px;
  margin-bottom: 0px;
}

.join-now {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  background-color: #00202b;
  border-radius: 0px 4px 4px 0px;
  text-align: center;
}

.build-your-body-text hr {
  height: 3px;
  background-color: #00202b !important;
  width: 70px;
  opacity: 1;
}

.join-today {
  background-color: #00708e;
  border: 10px;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  position: relative;
  margin-bottom: 30px;
  width: 200px;
  text-align: left;
}

.body-lifter-img {
  width: 70px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.club-slide {
  height: 1000px;
  position: relative;
}

.react-jinke-music-player .music-player {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
}

/* .slide-for-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
} */
.slide-for-club {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.height-inpus {
  width: 100%;
}

.join-today:hover {
  background-color: #00708e;
  box-shadow: none;
}

.books-appointment {
  background-color: #00708e;
  height: 40px;
  line-height: 20px;
  position: relative;
  width: 210px;
  text-align: left;
}

.books-appointment span {
  background-color: #00202b;
  height: 40px;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 40px;
  line-height: 35px;
  text-align: center;
  border-radius: 0px 4px 4px 0px;
}

.beauty-servcies-img img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.appointment-inputs {
  border: 1px solid #00708e;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  padding-left: 5px;
}

.max-width-login {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.spinner-box {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  cursor: pointer;
  display: flex;
  position: fixed;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.prenium-heading {
  font-size: 60px;
  position: relative;
}

.popup-noti .btn-close {
  position: absolute;
  right: 0px !important;
  background-color: #ff0e0e !important;
  padding: 10px 10px !important;
  opacity: 1 !important;
  border-radius: 50% !important;
  font-size: 10px;
}

.popup-noti .toast-header {
  padding: 0px 0px !important;
}

.starts-rate {
  margin: 0px 3px;
}

.starts-rate-box {
  margin-left: 10px;
}

.popup-noti {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.dreams-and-create {
  font-family: "star-free";
  font-size: 28px;
  position: absolute;
  bottom: -40px;
  color: #00708e;
  right: 160px;
}

svg {
  max-width: 100%;
}

.header {
  padding: 0px 100px;
}

.h4-poppine-bold {
  font-size: 30px;
  font-family: "poppins-bold";
}

.h5-poppine-bold {
  font-size: 24px;
  font-family: "poppins-bold";
}

/* .meet-our-teams-img img{
  width: 100%;
  height: ;
} */
.logo-header {
  width: 90px;
}

.qots-img {
  width: 40px;
}

.blog-pera {
  width: 60%;
}

.singe-review {
  padding: 5px 50px;
  color: #fff;
}

.btns-and-tabs {
  display: flex;
  justify-content: flex-end;
}

.blog-pri-btn img {
  width: 40px;
  height: 20px;
}

.blog-next-btn img {
  width: 40px;
  height: 20px;
}

.blogs-btns {
  margin-left: 20px;
  margin-top: 10px;
}

.clubs-slide-btns-box {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

.clubs-slide-btns {
  background-color: #6e6e6e;
  border: 0px;
  padding: 6px 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  line-height: 10px;
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

.slide-for-text button {
  border: 1px solid #fff;
  background-color: transparent;
  padding: 8px 40px;
  margin-top: 15px;
  color: #fff;
}

.green-hr-left {
  border-bottom: 4px solid #0b2f01;
  width: 70px;
  margin-left: 0px;
  opacity: 1;
}

.filter-classes {
  color: #00202b;
  font-family: "poppins-bold";
}

.filter-classes.active {
  color: #00202b !important;
  font-family: "poppins-bold";
  background-color: transparent !important;
  padding-top: 10px;
  border-bottom: 4px solid #00708e;
  border-radius: 0px;
}

.control-audios {
  position: relative;
  top: 8px;
  left: 10px;
  height: 30px;
}

.nav-header {
  height: 80px;
  border-bottom: 1px solid #00708e;
}

.rewiews-btns {
  position: absolute;
  right: 20px;
  bottom: 25px;
}

.client-says-imgs img {
  border: 10px solid #ffffff;
}

.bg-light-green {
  background-color: #00708e;
  transition: 0.5s all ease-in-out;
}

.bg-light-green:hover {
  transition: 0.5s all ease-in-out;
  color: #f8f9fa;
  box-shadow: 0 0 30px 5px;
}

.review-slider-box {
  padding: 20px 30px;
  position: relative;
  left: -20%;
  top: 30px;
}

.text-light-green {
  color: #00708e;
  font-size: 30px;
}

.do-yoga {
  font-size: 50px;
}

.free-yoga {
  font-size: 40px;
  margin-top: 30px;
  font-family: "star-free";
}

.theme-btns {
  background-color: #00708e;
  padding: 10px 20px;
  color: #fff;
  border-radius: 4px;
  border: 0px;
  font-size: 14px;
}

p {
  color: #000;
}

.font-14 {
  font-size: 14px;
  color: #000;
}

/* .from-the-momen {
} */

.from-the-moment hr {
  color: #00708e;
  opacity: 1;
  width: 70px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.light-green-color {
  color: #00708e;
}

.from-the-moment h2 {
  font-family: "poppins-light";
}

.light-gary-color {
  color: #000;
}

.read-more-read {
  background-color: #ff0e0e;
  border-radius: 4px;
  padding: 8px 30px;
  color: #fff;
  border: 0px;
}

.text-black {
  color: #000;
}

.text-black:hover {
  color: #000;
}

.div-w1-box {
  margin-top: auto;
  margin-bottom: auto;
}

.div-w3 {
  margin-top: 20px;
}

.from-the-moment {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.theme-btns:hover {
  background-color: #00708e;
}

.client-says-boxs {
  position: relative;
  margin-top: -230px;
}

.clean-sections {
  max-width: 750px;
  margin: 0 auto;
}

.clean-texts h3:first-child {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

.clean-texts {
  display: flex;
}

.clean-boxes {
  display: flex;
  margin-top: 30px;
}

.navs-item {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px !important;
  padding-right: 4px !important;
  color: #000 !important;
}

.clean-texts {
  margin-right: 30px;
}

.contact-btn {
  color: #fff !important;
  border-radius: 4px;
  width: 120px;
  text-align: center;
}

.green-hr {
  border-bottom: 4px solid #00708e;
  width: 70px;
  margin-left: 0px;
}

.blog-items {
  width: 260px !important;
  margin: 10px 15px;
  transition: 0.5s all ease-in-out;
}

.meet-our-teams-coulmn {
  transition: 0.5s all ease-in-out;
}

.meet-our-teams-coulmn:hover {
  transform: scale(1.03);
}

.img-zoom {
  transform: scale(1);
  transition-duration: 1.5s;
  transition-property: all;
  transition-delay: 0.5ms;
}

.img-zoom:hover {
  transition: transform 0.2s;
  transform: scale(1.4);
}

.h-rotate-360 {
  transition: 0.5s, color 0.1s;
  -webkit-transition: 0.5s, color 0.1s;
  -moz-transition: 0.5s, color 0.1s;
}

.h-rotate-360:hover {
  transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -moz-transform: rotateZ(360deg);
}

.blog-cover {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.blog-items:hover {
  transform: scale(1.1);
}

.blog-inner-text {
  background-color: #00708e;
  padding: 10px 20px;
}

.classes-baak-img-box {
  text-align: right;
  margin-bottom: -60px;
}

.classes-baak-img-box img {
  width: 60%;
}

.blog-inner-text h6 {
  font-family: "poppins-medium";
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;
}

.blog-inner-text p {
  font-family: "poppins-light";
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px;
}

.clock-img-icon {
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.slick-next:before {
  content: "" !important;
}

.slick-prev:before {
  content: "" !important;
}

.classes-slider-back {
  position: relative;
  margin-top: -90px;
}

.dots-img {
  float: left;
  position: absolute;
  width: 50px;
  top: 50%;
  left: -30px;
}

.logo-back {
  background-image: url(../images/back-logo.webp);
  background-attachment: fixed;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 500px 400px;
}

.footer-back {
  background-image: url(../images/back-footer.webp);
  height: 580px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.home-back {
  background-image: url(../images/home-back.webp);
  height: 650px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.img-icons {
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.fitzgerald-section {
  padding: 20px 30px;
}

.socials-icons-icons {
  color: #fff;
}

.socials-icons {
  display: flex;
}

.svg-icons {
  width: 90px;
}

.fitz-icon-box {
  display: flex;
  margin-top: 30px;
}

.meet-our-teams-img {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding-bottom: 30px;
}

.text-gray {
  color: #707070;
}

.meet-our-team-btns {
  text-align: center;
}

.meet-our-team-btns-btn {
  background-color: #ff0e0e;
  border: 0px;
  border-radius: 4px;
  color: #fff;
  padding: 10px 40px;
  font-size: 14px;
}

.fitz-icon-inner h5 {
  font-family: "poppins-medium";
  font-size: 26px;
  margin-bottom: 10px;
}

.copy-right-footer hr {
  height: 2px;
  color: #fff;
}

.footer-content {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  right: 0;
}

.text-left {
  text-align: left;
}

.socials-icons a {
  padding-left: 0px;
  padding-right: 14px;
  font-size: 18px;
}

.discover-box {
  max-width: 750px;
  margin: 0 auto;
  height: 500px;
  position: relative;
}

.discover-box-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.discover-icon-box {
  display: flex;
}

.discover-icon-text {
  color: #fff;
  position: relative;
  top: 8px;
}

.play-btns img {
  width: 70px;
}

.discover-icon-text h6 {
  margin-bottom: 0px;
}

.play-btn:active {
  background: transparent !important;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.play-btn:focus {
  background: transparent !important;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.discover-icon-text p {
  margin-bottom: 0px;
  font-size: 12px;
}

.meet-our-teams-text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.meet-our-teams-img-text {
  text-align: center;
  margin-top: 10px;
}

.meet-our-teams-img-text a {
  margin-left: 6px;
  margin-right: 6px;
}

.recent-news-box {
  background-color: #00202b;
  padding: 20px 20px;
  height: 100%;
}

.recent-news-box img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.post-locations {
  margin-right: 20px;
}

.text-half-white {
  color: #bbbbbb;
}

.post-timeings {
  display: flex;
}

.post-timeings img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.hr-centers {
  color: #00708e;
  opacity: 1;
  width: 70px;
  height: 2px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.fitness-boxes img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.we-rise-block {
  background-color: #00708e;
}

.rise-div-box-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.rise-div-box-column {
  display: flex;
}

.rise-div-box {
  padding: 40px 50px;
  align-self: center;
}

.font-dark-green {
  color: #00202b;
}

.fitness-boxes-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #00708e8c;
  width: 90%;
  height: 80%;
  padding: 30px 50px;
  text-align: center;
  display: flex;
  opacity: 0;
}

.fitness-boxes-hover-inner {
  align-self: center;
}

.fitness-boxes-hover-inner button {
  border: 0px;
  color: #fff;
  background-color: #ff0e0e;
  padding: 8px 25px;
  border-radius: 25px;
}

.fitness-boxes {
  height: 500px;
}

.fitness-boxes-hover hr {
  color: #00202b;
  opacity: 1;
  width: 70px;
  height: 2px;
  margin: 10px auto;
}

.cardio {
  background-color: #00708e8c;
  height: 50px;
  line-height: 50px;
  position: relative;
  top: -50px;
  text-align: center;
  color: #fff;
}

.fitness-boxes:hover .fitness-boxes-hover {
  transition: opacity 1s;
  opacity: 0;
}

.fitness-boxes:hover .cardio {
  display: none;
}

.advance-classes {
  margin-bottom: 0px;
}

.quick-footer-back {
  background-color: #00202b;
  margin-top: -25px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.socials-links-footers {
  margin-top: 30px;
}

.tag-btn {
  border: 1px solid #fff;
  margin-right: 10px;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  padding: 8px 20px;
  margin-bottom: 10px;
}

.div-w1 {
  position: relative;
  /* border-radius: 50%;*/
  animation-name: updown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition-duration: 1.5s;
  transition-property: all;
  transition-delay: 0ms;
}

@keyframes updown {
  0% {
    transform: translateY(1px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(1px);
  }
}

.div-w2 {
  position: relative;
  animation-name: upleft;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition-duration: 1.5s;
  transition-property: all;
  transition-delay: 0ms;
}

@keyframes upleft {
  0% {
    transform: translateX(1px);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(1px);
  }
}

.div-w3 {
  position: relative;
  animation-name: upright;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition-duration: 1.5s;
  transition-property: all;
  transition-delay: 0ms;
}

@keyframes upright {
  0% {
    transform: translateX(1px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(1px);
  }
}

.tag-btn-active:hover {
  background-color: #ff0e0e !important;
}

.socials-links-footers a {
  color: #fff;
  background-color: #00708e;
  padding: 1px 6px 4px 6px;
  font-size: 18px;
  border-radius: 3px;
  margin-right: 10px;
  margin-top: 30px;
}

.quick-Contacts-column h3 {
  font-family: "poppins-light";
  color: #fff;
  margin-bottom: 20px;
}

.quick-Contacts-column p {
  font-family: "poppins-light";
  color: #fff;
  margin-bottom: 5px;
}

.quick-Contacts-column span {
  color: #00708e;
  margin-right: 10px;
}

.footer-logos {
  text-align: center;
}

.footer-logos img {
  width: 270px;
}

.client-says-back {
  background-image: url(../images/client-says.webp);
  height: 270px;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  background-size: 100% 100%;
  margin-top: 50px;
}

.client-says-head-box {
  background-image: url(../images/client-text.webp);
  background-repeat: no-repeat;
  position: relative;
  height: 70px;
  background-position: 0px -15px;
  background-size: 400px 100px;
}

.client-says-head {
  color: #fff;
  position: relative;
  top: 10px;
}

.tranding-text {
  padding: 10px 30px;
}

.tranding-social a {
  color: #000;
  padding: 0px 10px;
}

.tranding-social a:hover {
  color: #00708e;
}

.socials-icons-icons-tranner {
  font-size: 18px;
}

.tranding-social {
  display: flex;
}

.tranding-text h5 {
  font-size: 30px;
  font-family: "poppins-bold";
  color: #00708e;
  height: 35px;
}

.tranding-text h6 {
  font-size: 18px;
  font-family: "poppins-light";
}

.popin-bold {
  font-family: "poppins-bold";
}

.popin-light {
  font-family: "poppins-light";
}

.build-your-body {
  background-image: url(../images/back-runner.webp);
  height: 800px;
  background-repeat: no-repeat;
  position: relative;
  background-position: center right;
  background-size: 50% 800px;
  margin-top: 70px;
}

.lifter-girl-img-btns {
  width: 300px;
}

.events-blocks {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100px;
  text-align: center;
}

/* .lifter-girl-img {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  text-align: center;
  bottom: -120px;
} */
.gym-overview-about {
  padding: 30px 30px;
  position: relative;
  display: grid;
  place-items: center;
}

.about-us-texts hr {
  height: 3px !important;
  background-color: #00708e !important;
  width: 70px;
  opacity: 1;
  margin-left: 0px;
}

.gym-overview-about {
  background-color: #00708e;
}

.aboutus-start {
  margin-top: 70px;
}

.about-us-img {
  position: relative;
  width: 90%;
  background-color: #00708e;
}

.about-us-img img {
  width: 100%;
  position: relative;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lifter-girl-img img {
  width: 280px;
}

.local_stream {
  height: 400px;
  width: 100%;
  border: 1px solid #00708e;
  margin-bottom: 30px;
}

.best-tranning-box {
  height: 100%;
  text-align: center;
  border: 5px solid;
  position: relative;
  left: -60px;
  top: 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.center-div {
  width: 100%;
  height: 100%;
}

.lifter-girl-img-1 img {
  width: 260px;
}

/* .lifter-girl-img-1 {
  position: absolute;
  left: 25px;
  margin: 0 auto;
  text-align: center;
  bottom: -40px;
} */
.lifter-girl-img-2 img {
  width: 270px;
}

/* .lifter-girl-img-2 {
  position: absolute;
  right: 25px;
  margin: 0 auto;
  text-align: center;
  bottom: -40px;
} */
.yoga-back {
  background-image: url("../images/yoga-back.webp");
  height: 680px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 160px;
  margin-top: 70px;
}

.best-traner-back {
  background-image: url("../images/tranner-back.webp");
  height: 700px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-top: 140px;
  margin-left: 0px;
  margin-right: 0px;
}

.best-tranning-box h2 {
  font-family: "Poppins-medium";
  color: #00202b;
  font-size: 60px;
  line-height: 80px;
}

.best-tranning-box h6 {
  display: flex;
  color: #00202b;
  font-family: "Poppins-medium";
  font-size: 20px;
  margin-left: 30px;
}

.best-tranning-box a {
  color: #00708e !important;
  position: relative;
  top: -8px;
  left: -15px;
}

.footballer-b {
  height: 100%;
  width: 80%;
  margin-left: -15px;
}

.build-your-body-inner {
  height: 800px;
  display: flex;
}

.build-your-body-text {
  align-self: center;
  width: 50%;
}

.discover-icon-img img {
  width: 100px;
}

.gimnas-text {
  margin-top: 40px;
  position: relative;
}

.show-767 {
  display: none;
}

.welcome-to-gim-img {
  width: 90%;
  background-color: #00708e;
}

.welcome-to-gim-img img {
  width: calc(100%);
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  left: 30px;
}

.pain-box {
  margin-top: 50px;
}

.fitz-icon-inner h5 {
  font-size: 22px;
}

.fitzgerald-section hr {
  height: 3px;
  background-color: #00708e !important;
  width: 70px;
  opacity: 1;
  margin-top: 25px;
  margin-bottom: 25px;
}

.pain-box-row {
  margin-left: 0px;
  margin-right: 0px;
}

.hide-767 {
  display: block;
}

.btn-struct-none {
  border: 0;
  background: transparent;
  outline: none;
}

.btns-and-tabs {
  display: flex;
  justify-content: flex-end;
}

.colmun-tranner {
  margin-bottom: 0px;
}

.tab-navs-item {
  list-style: none;
  text-align: right;
}

.tranner-blogs {
  margin-top: 50px;
}

.green-center-hr {
  height: 3px !important;
  background-color: #00708e !important;
  width: 70px;
  opacity: 1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.tranner-rows {
  margin-bottom: 50px;
}

.client-says-text-box p {
  color: #fff;
}

.white-hrs {
  height: 3px !important;
  background-color: #fff;
  width: 80px;
  opacity: 1;
}

.footers-texts {
  position: absolute;
  bottom: -5px;
  padding-right: 5%;
}

.clsoe-btn-modal {
  background-color: transparent;
  border: 0;
  font-size: 22px;
  position: absolute;
  right: 20px;
  z-index: 99;
  color: red;
}

.audio-player {
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 10px;
  width: 270px;
}

.boys-fitenss {
  display: none;
}

.girl-fitenss {
  display: none;
}

.client-says-imgs img:hover {
  transform: scale(1.1);
  color: rgb(183 181 24 / 79%);
  box-shadow: 0 0 31px 5px;
}

.club-per-month-box-ani {
  transition: 0.5s all ease-in-out;
}

.club-per-month-box-ani:hover {
  transform: scale(1.05);
  color: rgb(183 181 24 / 79%);
  box-shadow: 0 0 31px 5px;
}

.club-per-month-box {
  position: relative;
  text-align: center;
}

.club-per-month-box img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.client-says-imgs img {
  transition: 0.5s all ease-in-out;
}

.club-classes-text {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
  width: 100%;
}

.club-classes-text button {
  background-color: #ff0e0e;
  border: 0;
  color: #fff;
  font-weight: 600;
  padding: 5px 25px;
  border-radius: 4px;
  margin-top: 10px;
}

.welcome-cont {
  margin-top: 90px;
}

.lending-classes {
  background-color: #00202b;
}

.testmonials-back {
  background-color: #00708e;
  padding-top: 70px;
  padding-bottom: 70px;
}

.dark-green-center-hr {
  width: 70px;
  height: 2px;
  color: #00202b;
  margin: 10px auto;
}

.testmonials-slide-for-text {
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.testmonials-users-detail {
  margin-top: 30px;
}

.testmonials-users-detail img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.testmonials-users-detail h6 {
  font-family: "poppins-light";
}

.testmonials-users-detail p {
  font-family: "poppins-light";
  font-size: 12px;
}

.testmonials-slide-btns-pri {
  position: absolute;
  top: 25%;
  border: 0px;
  background-color: #00202b;
  color: #fff;
  border-radius: 50%;
  line-height: 30px;
  height: 35px;
  font-size: 16px;
  padding: 0px 10px;
  left: 0px;
}

.testmonials-slide-btns-next {
  position: absolute;
  top: 25%;
  border: 0px;
  background-color: #00202b;
  color: #fff;
  border-radius: 50%;
  line-height: 30px;
  height: 35px;
  font-size: 16px;
  padding: 0px 10px;
  right: 0px;
}

.back-contact-us {
  background-color: #00202b;
  padding-bottom: 0px;
}

.contact-us-box-img {
  background-image: url(../images/ccc-1.jpg);
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contact-us-box {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}

.contact-us-link a {
  color: #00708e;
  padding-right: 15px;
}

.contact-us-line {
  width: 1px;
  height: 25px;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}

.contact-us-info {
  display: flex;
}

.contact-us-info p {
  color: #fff;
}

.contact-us-box h2 {
  color: #fff;
  font-family: "poppins-light";
}

.contact-us-box hr {
  color: #00708e;
  width: 70px;
  height: 2px;
  opacity: 1;
  margin-bottom: 40px;
}

.contact-us-inpus {
  border: 1px solid #00708e;
  width: 100%;
  padding: 8px 15px;
  color: #fff;
  background-color: transparent;
  margin-bottom: 24px;
  font-size: 14px;
  border-radius: 4px;
}

.contact-submit {
  background-color: #ff0e0e;
  border: 0;
  border-radius: 4px;
  width: 100%;
  padding: 8px 10px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}

.contact-us-inpus-textarea {
  border: 1px solid #00708e;
  width: 100%;
  padding: 8px 15px;
  color: #fff;
  background-color: transparent;
  margin-bottom: 24px;
  font-size: 14px;
  border-radius: 4px;
}

.change-btn {
  background-color: #fff;
  text-align: center;
  padding-bottom: 10px;
}

.change-btn button {
  background-color: transparent;
  border: 0;
  color: #929292;
  margin: 0px 00px;
}

.rise-m {
  display: none;
}

.online-clases {
  margin-bottom: -70px !important;
}

.online-clases-img {
  width: 100%;
}

.appointment-form {
  margin-top: 40px;
}


.arrow-left{
font-size: 35px;
}
.arrow-left-com{
  display: block;}

.arrow-right-com{
  display: block;
}

.arrow-sliding-left {
position: relative;
 -webkit-animation: slideleft 3s linear infinite; 
 animation: slideleft 3s linear infinite;
}

.arrow-sliding-right {
  position: relative;
   -webkit-animation: slideright 3s linear infinite; 
   animation: slideright 3s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s; 
    animation-delay: 1s;
}
.delay2 {
  -webkit-animation-delay: 2s; 
    animation-delay: 2s;
}
.delay3 {
  -webkit-animation-delay: 3s; 
    animation-delay: 3s;
}

@-webkit-keyframes slideleft {
    0% { opacity:0; transform: translateX(4vw); }	
   50% { opacity:1; transform: translateX(2vw); }	
  100% { opacity:0; transform: translateX(0vw); }	
}
@keyframes slideleft {
    0% { opacity:0; transform: translateX(4vw); }	
   50% { opacity:1; transform: translateX(2vw); }	
  100% { opacity:0; transform: translateX(0vw); }	
}


@-webkit-keyframes slideright {
  0% { opacity:0; transform: translateX(0vw); }	
 50% { opacity:1; transform: translateX(2vw); }	
100% { opacity:0; transform: translateX(4vw); }	
}
@keyframes slideright {
  0% { opacity:0; transform: translateX(0vw); }	
 50% { opacity:1; transform: translateX(2vw); }	
100% { opacity:0; transform: translateX(4vw); }	
}
.heading-arrow-top{
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-sign-btn{
  background-color: #ff0e0e;
  border-radius: 4px;
  padding: 8px 12px;
  color: #fff;
  border: 0px;
  text-decoration: none;
}
.modal-sign-box{
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 1750px) {
  .clean-boxes {
    margin-left: 15px;
  }
}

@media (max-width: 1600px) {
  .club-slide {
    height: 800px;
  }
}

@media (max-width: 1400px) {
  .rise-div-box-img img {
    height: 350px;
  }

  .club-slide {
    height: 700px;
  }
}

@media (max-width: 1300px) {
  .nav-header-item-front a {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
}

@media (max-width: 1200px) {
  .nav-header-item-front a {
    font-size: 13px;
  }

  .tranding-text {
    padding: 10px 20px;
  }

  .best-tranning-box h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .best-tranning-box h6 {
    font-size: 18px;
  }

  .yoga-back {
    background-position: top;
    background-size: cover;
  }

  .build-your-body {
    height: 600px;
    background-size: 50% 600px;
  }

  .build-your-body-inner {
    height: 600px;
  }
}

@media (max-width: 1025px) {
  .navs-item {
    margin-left: 5px;
    margin-right: 5px;
  }

  .clean-boxes {
    margin-top: 80px;
    justify-content: center;
  }

  .home-back {
    background-image: url("../images/back-responsive.webp");
  }

  .boys-fitenss {
    display: block;
    width: 200px;
    position: absolute;
    bottom: -110px;
  }

  .girl-fitenss {
    display: block;
    width: 270px;
    position: absolute;
    bottom: -80px;
    right: 20px;
  }
}

@media (max-width: 992px) {
  .heading-arrow-top {
  flex-direction: column;
  gap: 30px;

}
  .basic-navbar-nav-info {
    margin-top: 20px;
  }

  .nav-header-item-front a {
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .logo-header {
    width: 70px;
  }

  .books-appointment {
    margin-top: 35px;
  }

  .about-us-img {
    width: 100%;
  }

  .about-us-texts {
    text-align: center;
  }

  .about-us-texts hr {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .about-us-img img {
    width: calc(100% - 40px);
  }

  .lifter-girl-img img {
    width: 200px;
  }

  .lifter-girl-img-1 img {
    width: 200px;
  }

  .lifter-girl-img-2 img {
    width: 200px;
  }

  @keyframes upleft {
    0% {
      transform: translateY(1px);
    }

    50% {
      transform: translateY(20px);
    }

    100% {
      transform: translateY(1px);
    }
  }

  @keyframes upright {
    0% {
      transform: translateY(1px);
    }

    50% {
      transform: translateY(20px);
    }

    100% {
      transform: translateY(1px);
    }
  }

  .rise-d {
    display: none;
  }

  .rise-m {
    display: block;
  }

  .footer-logos {
    margin-bottom: 48px;
  }

  .popular-tags-btn {
    text-align: center;
  }

  .quick-Contacts-column {
    text-align: center;
    margin-bottom: 50px;
  }

  .contact-us-box {
    width: 80%;
    margin-bottom: 40px;
  }

  .recent-news-column {
    margin-bottom: 20px;
  }

  .welcome-cont {
    margin-top: 40px;
  }

  .from-the-moment {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
  }

  .club-per-month-box img {
    height: 300px;
    object-fit: cover;
  }

  .club-slide {
    height: 560px;
  }

  .build-your-body-text h2 {
    font-size: 38px;
  }

  .build-your-body-text h4 {
    font-size: 24px;
  }

  .view-featues-box {
    text-align: center;
  }

  .colmun-tranner {
    margin-bottom: 20px;
  }

  .review-slider-box {
    left: 0;
  }

  .singe-review {
    padding: 5px 0px;
  }

  .singe-review p {
    color: #fff !important;
  }

  .client-says-text-box p {
    color: #000;
    text-align: center;
  }

  .client-says-imgs img {
    border: 10px solid #ffffff;
    width: 100%;
  }

  /* .client-says-head-box h1{
  color: #000 !important;
} */
  .client-says-head {
    color: #000;
    text-align: center;
  }

  .client-says-head-box p {
    color: #000 !important;
  }

  .client-says-imgs {
    height: 400px;
    overflow: hidden;
  }

  .blog-pera {
    margin-top: 20px;
    width: 100%;
  }

  .best-traner-back {
    height: auto;
    margin-bottom: 100px;
  }

  .dreams-and-create {
    font-size: 28px;
    position: relative;
    bottom: 0px;
    right: 0px;
    display: block;
  }

  .clean-sections {
    max-width: 100%;
    padding: 0px 30px;
  }

  .clean-boxes {
    justify-content: center;
    margin-top: 50px;
  }

  #basic-navbar-nav {
    background-color: #fff;
    z-index: 99;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .discover-box {
    max-width: 100%;
    margin: 0 40px;
  }

  .header {
    padding: 0px 20px;
  }
}

@media (max-width: 767px) {
  .basic-navbar-nav-info {
    margin-top: 70px;
  }

  .react-jinke-music-player-mobile-cover {
    display: none !important;
  }

  .react-jinke-music-player-mobile {
    top: unset !important;
  }

  .testmonials-slide-btns-next {
    position: absolute;
    right: 50px;
    top: 0px;
  }

  .testmonials-slide-btns-pri {
    position: absolute;
    left: 50px;
    top: 0px;
  }

  .meet-our-teams-text {
    width: 100%;
  }

  .meet-our-teams-coulmn {
    margin-bottom: 20px;
  }

  .meet-our-teams-imgss {
    width: 100%;
  }

  .from-the-moment hr {
    margin-left: auto;
    margin-right: auto;
  }

  .from-the-moment {
    text-align: center;
  }

  .div-w1-box {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .div-w1 img {
    width: 100%;
  }

  .div-w2 img {
    width: 100%;
  }

  .div-w3 img {
    width: 100%;
  }

  .club-slide {
    height: 370px;
  }

  .clubs-slide-btns-box {
    right: 0px;
    left: 0px;
    margin: auto;
    text-align: center;
  }

  .slide-for-text {
    left: 0px;
    right: 0px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  .client-says-back {
    margin-top: 10px;
  }

  .clean-boxes {
    margin-top: 70px;
  }

  .white-hrs {
    margin-left: auto;
    margin-right: auto;
  }

  .get-in-touch-footer {
    text-align: center;
  }

  .socials-icons {
    justify-content: center;
  }

  .footers-texts {
    position: relative;
    bottom: 0px;
    padding-right: 0;
  }

  .tranding-text {
    padding: 30px 0px;
  }

  .socials-icons-icons-tranner {
    font-size: 14px;
  }

  .tranding-text h5 {
    font-size: 24px;
  }

  .tranding-text h6 {
    font-size: 14px;
  }

  .tranding-text p {
    font-size: 12px;
  }

  .classes-slider-back {
    margin-top: 0px;
  }

  .best-tranning-box {
    left: 0px;
    padding: 20px 20px;
  }

  .best-tranning-box h6 {
    justify-content: center;
  }

  .yoga-back {
    height: auto;
  }

  .do-yoga {
    font-size: 34px;
  }

  .free-yoga {
    font-size: 28px;
  }

  .build-your-body-text h4 {
    font-size: 30px;
  }

  .build-your-body-text h2 {
    font-size: 30px;
  }

  .person-gim-info h2 {
    font-size: 30px;
  }

  .person-gim-info {
    text-align: center;
  }

  .gimnas-text {
    text-align: center;
  }

  .build-your-body-inner {
    text-align: center;
  }

  .build-your-body-text hr {
    margin: 20px auto;
  }

  .prenium-heading {
    font-size: 40px;
  }

  .starts-rate-box {
    margin-left: 0px;
    display: block;
  }

  .show-767 {
    display: block;
  }

  .hide-767 {
    display: none;
  }

  .events-blocks {
    position: static;
  }

  .body-img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 39px;
  }

  .build-your-body {
    background-image: none;
    height: auto;
    background-size: auto;
    margin-top: 20px;
  }

  .build-your-body-inner {
    height: auto;
    display: block;
  }

  .build-your-body-text {
    width: 100%;
  }

  .footer-back {
    height: 850px;
  }

  .discover-icon-text {
    position: static;
    margin: auto 0;
  }
}

@media (max-width: 650px) {
  .lifter-girl-img-1 {
    position: static;
    margin-bottom: 20px;
  }

  .lifter-girl-img-2 {
    position: static;
    margin-bottom: 20px;
  }

  .lifter-girl-img {
    position: static;
    margin-bottom: 20px;
  }

  .yoga-back {
    height: auto;
    padding-bottom: 30px;
  }
}

@media (max-width: 576px) {
  .footer-back {
    height: 960px;
  }

  .contact-us-info {
    display: block;
    text-align: center;
  }

  .contact-us-link {
    text-align: center;
  }

  .contact-us-line {
    display: none;
  }

  .best-tranning-box h6 {
    margin-left: 0px;
  }

  .girl-fitenss {
    width: 180px;
    bottom: 75px;
    right: 10px;
  }

  .boys-fitenss {
    width: 150px;
    bottom: 60px;
  }

  .rewiews-btns {
    position: relative;
    right: 8px;
    bottom: 0px;
  }

  .btns-and-tabs {
    display: block;
    justify-content: center;
    text-align: center;
  }

  .best-tranning-box a {
    top: 0px;
    left: 0px;
  }

  .best-tranning-box h6 {
    display: block;
  }

  .fitz-icon-box {
    display: block;
    text-align: center;
  }

  .img-icons {
    margin-bottom: 20px;
    margin-right: 0px;
  }

  .body-img {
    width: 100%;
  }

  .discover-box-text {
    top: 40%;
    text-align: center;
  }

  .prenium-heading {
    font-size: 28px;
  }

  .clean-boxes {
    display: block;
    margin-left: 0px;
  }

  .clean-sections {
    position: relative;
    top: -110px;
  }

  .clean-texts h3:first-child {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .clean-texts {
    display: block;
    justify-content: center;
    margin-bottom: 30px;
    text-align: center;
    margin-right: 0px;
  }

  .discover-icon-box {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .discover-box {
    height: 100%;
  }

  .build-your-body {
    margin-top: 50px;
  }

  .home-back {
    height: 900px;
  }
}

@media (max-width: 576px) {
  .boys-fitenss {
    width: 40%;
    bottom: 60px;
    left: 0px;
  }

  .girl-fitenss {
    width: 40%;
    bottom: 100px;
    right: 10px;
  }
}

.player-content svg {
  width: 30px !important;
  max-width: 30px !important;
}

.progress-bar {
  background-color: transparent;
}
